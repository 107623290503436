import { color, media } from "@sencrop/ui-components";
import { webtext, WebTitle } from "@sencrop/ui-web";
import { IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";
import ImageElement from "./ImageElement";
import React from "react";
import LinkElement from "../containers/LinkElement";
import { Link } from "../containers/LinkRenderer";

type ArgumentItemProps = {
  item?: {
    title?: string;
    text?: {
      childMarkdownRemark?: {
        html?: string;
      };
    };
    image?: {
      title?: string;
      gatsbyImageData?: IGatsbyImageData;
    };
    link: GatsbyTypes.Maybe<GatsbyTypes.LinkFragmentFragment>;
  };
};

const ArgumentItem = ({ item }: ArgumentItemProps) => {
  if (!item) {
    return null;
  }

  return (
    <ArgumentContainer>
      <ImageContainer>
        {item.image && (
          <ImageElement
            gatsbyImageData={item.image.gatsbyImageData}
            style={{ height: "100%", width: "100%" }}
            alt={item.image.title || ""}
          />
        )}
      </ImageContainer>
      <Content>
        <WebTitle
          as="h3"
          size="m"
          color="branding-secondary"
          style={{ marginBottom: "6px" }}
        >
          {item.title}
        </WebTitle>
        {item.text?.childMarkdownRemark?.html && (
          <Description
            dangerouslySetInnerHTML={{
              __html: item.text.childMarkdownRemark.html,
            }}
            style={{ whiteSpace: "pre-wrap" }}
          />
        )}
        {item?.link && (
          <LinkElement
            link={item?.link as Link}
            style={{ marginTop: "1rem" }}
            centered
          >
            {item?.link.label}
          </LinkElement>
        )}
      </Content>
    </ArgumentContainer>
  );
};

export default ArgumentItem;

const ArgumentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 6px;
  padding: 1rem;
  border: 1px solid ${color("border-primary")};
  ${media.greaterThan("tablet")`
    padding: 1.5rem;
  `}
`;
const ImageContainer = styled.div`
  margin-right: 1rem;
  width: 80px !important;
  * {
    width: 80px !important;
  }
  img {
    object-fit: contain !important;
  }
  ${media.greaterThan("tablet")`
    margin-right: 1rem;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > *:last-child {
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  p {
    color: ${color("text-primary:75")};
    ${webtext("text", "m")};
  }
`;
