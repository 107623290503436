import { color, media } from "@sencrop/ui-components";
import { WebTitle, webtext } from "@sencrop/ui-web";
import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import ArgumentItem from "../components/ArgumentItem";
import { blockCss, containerCss, contentCss } from "../helpers/style";
import ImageElement from "../components/ImageElement";

const SectionArguments = ({
  topIcon,
  title,
  subhead,
  sectionText,
  argumentItems,
}: GatsbyTypes.SectionArgumentsFragmentFragment) => {
  return (
    <Background>
      <Wrapper>
        {topIcon && (
          <TopIcon>
            <ImageElement
              gatsbyImageData={topIcon.gatsbyImageData}
              style={{ height: "100%", width: "100%" }}
              alt={topIcon.title || ""}
            />
          </TopIcon>
        )}
        <Header>
          {subhead && (
            <SubHead>
              <WebTitle size="s" color="branding-secondary">
                {subhead}
              </WebTitle>
            </SubHead>
          )}
          <TitleContent>
            <WebTitle as="h2" size="xl" color="primary">
              {title}
            </WebTitle>
          </TitleContent>
          {sectionText?.childMarkdownRemark?.html && (
            <TextContent
              dangerouslySetInnerHTML={{
                __html: sectionText.childMarkdownRemark.html as string,
              }}
              style={{ whiteSpace: "pre-wrap" }}
            />
          )}
        </Header>
        <ArgumentItemsSection>
          {argumentItems &&
            argumentItems.map((item, index: number) => (
              <ArgumentItem key={index} item={item} />
            ))}
        </ArgumentItemsSection>
      </Wrapper>
    </Background>
  );
};

export default SectionArguments;

export const fragment = graphql`
  fragment SectionArgumentsFragment on ContentfulSectionArguments {
    topIcon {
      gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
      title
    }
    title
    subhead
    sectionText: text {
      childMarkdownRemark {
        html
      }
    }
    argumentItems {
      image {
        gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        title
      }
      title
      text {
        childMarkdownRemark {
          html
        }
      }
      link {
        ...LinkFragment
      }
    }
  }
`;

const Wrapper = styled.div`
  ${containerCss}
  ${contentCss}
  ${blockCss}
  z-index: 2;
  position: relative;
  padding-top: 4rem;
  padding-bottom: 2rem;
  background-color: ${color("background-primary:10")};
  ${media.greaterThan("tablet")`
    padding-top: 4.5rem;
    padding-bottom: 2rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  `}
`;

const Background = styled.div`
  ${media.greaterThan("tablet")`
    position: relative;
    padding-bottom: 2.5rem;
    :before {
      z-index:-1;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: ${color("background-primary")};
    }
  `}
`;

const TopIcon = styled.div`
  z-index: 10;
  height: 80px;
  width: 80px;
  margin: 0 auto;
  position: absolute;
  top: -32px;
  left: calc(50% - 40px);
  img {
    object-fit: contain !important;
  }
  ${media.greaterThan("tablet")`
    top: -24px;
  `}
`;

const SubHead = styled.div`
  text-align: center;
  margin: 0 auto;
  p {
    color: ${color("branding-secondary-variant")};
  }
  margin-bottom: 0.5rem;
`;

const TitleContent = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
`;
const Header = styled.div`
  max-width: 796px;
  margin: 0 auto;
`;

const TextContent = styled.div`
  text-align: center;
  p {
    color: ${color("text-primary:75")};
    ${webtext("text", "l")};
  }
`;

const ArgumentItemsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  margin-top: 1.5rem;
  ${media.greaterThan("tablet")`
    margin-top: 2rem;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  `}
`;
